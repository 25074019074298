import styled, { DefaultTheme } from 'styled-components';
import { device } from '../../../lib/media-query';
import { Variant } from '../../../lib/ui-types';

const getBackgroundColor = (variant: Variant, theme: DefaultTheme) => {
    switch (variant) {
        case 'dark':
            return theme.palette.common.black;
        case 'light':
            return theme.palette.common.offWhite;
        case 'primary':
            return theme.palette.primary.main;
        case 'link':
        case 'transparent':
            return 'transparent';
        default:
            return theme.palette.primary.main;
    }
};

const getColor = (variant: Variant, theme: DefaultTheme) => {
    switch (variant) {
        case 'dark':
        case 'primary':
            return theme.palette.common.offWhite;
        case 'light':
            return theme.palette.common.black;
        case 'link':
            return theme.palette.primary.main;
        case 'transparent':
            return theme.palette.common.white;
        default:
            return theme.palette.common.offWhite;
    }
};

const getBorder = (variant: Variant, theme: DefaultTheme) => {
    if (variant === 'light') {
        return `1px solid ${theme.palette.common.black}`;
    }

    if (variant === 'transparent') {
        return `1px solid ${theme.palette.common.white}`;
    }

    return 'none';
};

const getSelfAlignment = (alignment?: 'left' | 'center' | 'right' | 'stretch') => {
    if (!alignment) {
        return 'unset';
    }

    switch (alignment) {
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'right':
            return 'flex-end';
        case 'stretch':
            return 'stretch';

        default:
            return 'unset';
    }
};

export const StyledButton = styled.button<{
    variant: Variant;
    showAsBlock: boolean;
    alignment?: 'left' | 'center' | 'right' | 'stretch';
    unsetMinWidth: boolean;
}>`
    &,
    &:link,
    &:visited {
        position: relative;
        display: ${(props) => (props.variant !== 'link' ? 'flex' : 'inline-block')};
        align-items: center;
        justify-content: center;
        text-transform: ${(props) => (props.variant !== 'link' ? 'uppercase' : 'none')};
        text-decoration: ${(props) => (props.variant !== 'link' ? 'none' : 'underline')};
        padding-right: ${(props) => (props.variant !== 'link' ? '30px' : '0')};
        padding-left: ${(props) => (props.variant !== 'link' ? '30px' : '0')};
        padding-top: ${(props) => (props.variant !== 'link' ? '10px' : '0')};
        padding-bottom: ${(props) => (props.variant !== 'link' ? '10px' : '0')};
        height: ${(props) => (props.variant !== 'link' ? '45px' : 'auto')};
        border-radius: 20px;
        transition: all 0.2s;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-align: center;

        //Change for the <button> element
        border: none;
        cursor: pointer;
        color: ${(props) => getColor(props.variant, props.theme)};
        background-color: ${(props) => getBackgroundColor(props.variant, props.theme)};
        border: ${(props) => getBorder(props.variant, props.theme)};

        width: ${(props) => (props.showAsBlock ? '100%' : 'auto')};
        min-width: ${(props) => (props.variant !== 'link' && !props.unsetMinWidth ? '150px' : 'auto')};
    }

    &:hover {
        transform: ${(props) => (props.variant !== 'link' ? 'translateY(-3px)' : '0')};
        box-shadow: ${(props) => (props.variant !== 'link' ? props.theme.shadows.large : 'none')};
    }

    &:active,
    &:focus {
        outline: none;
        transform: ${(props) => (props.variant !== 'link' ? 'translateY(-1px)' : '0')};
        box-shadow: ${(props) => (props.variant !== 'link' ? props.theme.shadows.small : 'none')};
    }

    &:disabled {
        filter: grayscale(1);
        opacity: 0.4;
        cursor: not-allowed;
        transform: none;
    }

    @media ${device.tablet} {
        align-self: ${(props) => getSelfAlignment(props.alignment)};
    }
`;

export const ChildrenWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
