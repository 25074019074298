import { FC } from 'react';
import { ISvgIcon } from './svg-icon.props';
import { StyledCustomColorSvg, StyledSvg } from './svg-icon.styled';

/* Example
    <SvgIcon iconName='profile-neutral' />
*/
export const SvgIcon: FC<ISvgIcon> = ({ iconName, iconBody, color = 'default', onClick, customColor }: ISvgIcon) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const svgContent = iconBody || require(`../../../../public/icons/${iconName}.svg`).default();
        if (customColor && customColor.length > 0) {
            return (
                <StyledCustomColorSvg aria-hidden onClick={onClick} customColor={customColor}>
                    {svgContent}
                </StyledCustomColorSvg>
            );
        }
        return (
            <StyledSvg aria-hidden onClick={onClick} iconType={color}>
                {svgContent}
            </StyledSvg>
        );
    } catch (e) {
        if (typeof window !== 'undefined') {
            window.console.warn(e);
        }
    }

    return <></>;
};
