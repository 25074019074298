import { FC } from 'react';
import { ShiftBy } from '../shift-by';
import { Spinner } from '../spinner';
import { IButton } from './button.props';
import { StyledButton, ChildrenWrapper } from './button.styled';

export const Button: FC<IButton> = ({
    className,
    children,
    onClick: action,
    showAsBlock = false,
    variant = 'light',
    disabled = false,
    loading = false,
    type = 'button',
    alignSelf,
    unsetMinWidth = false,
}) => {
    return (
        <StyledButton
            className={className}
            variant={variant}
            type={type}
            disabled={disabled || loading}
            onClick={action}
            showAsBlock={showAsBlock}
            alignment={alignSelf}
            unsetMinWidth={unsetMinWidth}
        >
            {loading && (
                <ShiftBy x={-4} y={2}>
                    <Spinner size="small" variant={variant} />
                </ShiftBy>
            )}
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </StyledButton>
    );
};
